import { useCallback, useEffect, useState, Text } from "react"
import ExperienceCard from './ExperienceCard'


 const Experience = () => {

    const [experienceList, setExperienceList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setExperienceList(prevList => [
            { 
                index: 0,
                title: "DevOps Engineer Intern", 
                description: "I am focused on automating CI/CD pipelines and integrating self-service solutions, leveraging tools like Jenkins, Docker, and custom scripting to streamline system provisioning and deployments. I am optimizing cloud infrastructure on AWS using Kubernetes, along with delivering coded automation artifacts (Python, Bash) and YAML-based CI/CD pipelines.", 
                dates: "Oct 2024 - Present",
                company: "AbbVie", },
            { 
                index: 1,
                title: "Software Developer Intern", 
                description: "I engaged in full-stack development, utilizing Angular for the front-end and Java with Spring Boot for the back-end, while integrating NoSQL databases to enhance data management. Through this role, I gained a comprehensive understanding of the entire software development lifecycle, from designing responsive user interfaces to building robust server-side logic with Spring Boot and ensuring efficient data handling through NoSQL solutions.", 
                dates: "May 2024 - Aug 2024",
                company: "Uline", },
            { 
                index: 2,
                title: "President, Lead Engineer", 
                description: "I oversee the strategic direction and operational management of the organization, fostering a collaborative environment for members to explore cutting-edge financial technologies. I lead technical projects, mentor teams on developing innovative solutions in areas like blockchain and algorithmic trading, and organize workshops and speaker sessions to enhance technical and professional skills.", 
                dates: "Jan 2023 - Present",
                company: "UW-Madison FinTech Club" },
            { 
                index: 3,
                title: "Software Engineer Intern", 
                description: "I collaborated with a team of engineers to implement a Gradient Boosting Machine (GBM)-based machine learning model, leveraging transaction history and user behavior data to enhance fraud detection accuracy by 25%. Additionally, I developed a React and .NET-based dashboard that streamlined fraud analysis processes, reducing processing times by 40% and improving operational efficiency.", 
                dates: "May 2023 - Aug 2023",
                company: "UW Credit Union", },
        ]);

    }, []);
    
    return (
        <div className="App" style={{paddingLeft: "22.5%", paddingRight: "17.5%", paddingTop: "0.25%", font: "droid sans"}}>
            {experienceList?.map((experience, index) => (
                <ExperienceCard key={index} experience={experience}>hi</ExperienceCard>
            ))}
        </div>
    );
}

export default Experience;

