import React from "react"
import { useState, useEffect, useContext} from "react"
import { Card, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'



function ExperienceCard({experience}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isTagHovered, setIsTagHovered] = useState(false);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showFullDescription, setShowFullDescription] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 20);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const pressButton = (experience) => {
        if (experience.company === 'Uline') {
            navigate('/experience/uline');
        } else if (experience.company === 'UW Credit Union') {
            navigate('/experience/uwcu');
        } else if (experience.company === 'AbbVie') {
            navigate('/experience/abbvie');
        } else if (experience.company === 'UW-Madison FinTech Club') {
            navigate('/experience/uw-madison_fintech_club');
        }
    }

    return (
        <Card
            style={{opacity: loaded ? 1 : 0, transition: 'opacity ' + experience.index * 0.75 + 's ease', margin: "2rem", padding: "0.5rem", height: "100%", backgroundColor: isHovered ? "#f0f0f0" : "transparent", font: "droid sans"}} 
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)}
        >
            <Card.Body>
                <a href={experience.url} style={{textDecoration: "none", color: "black"}} target={(experience.url) ? null: "_blank"} onPress={() => pressButton(experience)}>
                    <Link style={{textDecoration: "none", color: "black"}} to={(experience.company === "Uline") ? "/experience/uline" : (experience.company === "UW Credit Union") ? "/experience/uwcu" : (experience.company === "AbbVie") ? "/experience/abbvie" : (experience.company === "UW-Madison FinTech Club") ? "/experience/uw-madison_fintech_club" : null} target={(experience.company === "Developer Secure") ? "_blank": null}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                        {windowWidth > 1100 ? (
                            <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                flexWrap: "wrap", // Allow wrapping if needed
                                }}
                            >
                                <p
                                style={{
                                    display: "inline",
                                    color: "#0058e8",
                                    fontWeight: "bold",
                                    margin: 0,
                                    fontSize: 22,
                                    whiteSpace: "nowrap",
                                }}
                                >
                                #
                                </p>
                                <p
                                style={{
                                    display: "inline",
                                    fontWeight: "bold",
                                    margin: 0,
                                    paddingLeft: "5px",
                                    fontSize: Math.max(12, Math.min(18, windowWidth / 60)),
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                                >
                                {experience.title}
                                </p>
                            </div>
                            ) : (
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: windowWidth > 1100 ? "start" : "center",
                                        width: "100%",
                                        flexWrap: "wrap", // Allow wrapping if needed
                                        textAlign: windowWidth > 1100 ? "left" : "center",
                                    }}
                                    >
                                    <p
                                        style={{
                                        display: "inline",
                                        color: "#0058e8",
                                        fontWeight: "bold",
                                        margin: 0,
                                        fontSize: 22, // Fixed font size for the '#'
                                        whiteSpace: "nowrap",
                                        }}
                                    >
                                        #
                                    </p>
                                    <p
                                        style={{
                                        fontWeight: "bold",
                                        margin: 0,
                                        paddingLeft: "5px",
                                        fontSize: windowWidth <= 1100 ? "18px" : "clamp(14px, 2vw, 18px)", // Adjust font only if card is too big
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        }}
                                    >
                                        {experience.title}
                                    </p>
                                    </div>
                            </div>
                            )}

                            {console.log(windowWidth)}
                            {(windowWidth > 1100) ? <p style={{ display: "inline", margin: 0, fontSize: 16, color: "gray"}}>{experience.dates}</p> : null}
                        </div>
                        {(windowWidth > 1100) 
                            ? <p style={{ paddingTop: "0.5%", }}>{experience.company}</p>
                            : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <p
                                    style={{
                                        margin: 0,
                                        paddingTop: "0.5%",
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        whiteSpace: "nowrap", // Prevent wrapping
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    >
                                    {experience.company}
                                </p>
                            </div>
                        }
                        {console.log(`Window Width: ${experience.description.length + windowWidth}`)}
                        <p style={{ paddingTop: "1.5%" }}>
                        {(windowWidth > 1000 || showFullDescription)
                            ? experience.description + " "
                            : experience.description.substring(0, Math.max(150, experience.description.length + windowWidth - 1000)) + " ... "}
                        {windowWidth <= 1000 && !showFullDescription && (
                            <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowFullDescription(true);
                            }}
                            style={{
                                background: "none",
                                border: "none",
                                color: "#0058e8",
                                cursor: "pointer",
                                padding: "0",
                                fontSize: "14px",
                            }}
                            >
                            Show More
                            </button>
                        )}
                        {windowWidth <= 1000 && showFullDescription && (
                            <button
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setShowFullDescription(false);
                            }}
                            style={{
                                background: "none",
                                border: "none",
                                color: "#0058e8",
                                cursor: "pointer",
                                padding: "0",
                                fontSize: "14px",
                            }}
                            >
                            Show Less
                            </button>
                        )}
                        </p>
                        <div>
                            {experience.tags && experience.tags.map((tag, index) => (
                                <span key={index} style={{ display: "inline-block", color: "red", margin: 0, fontSize: 14, marginRight: "20px" }}>
                                    <a href={(experience.company === "Developer Secure") ? experience.url : "google.com"} target="_blank" style={{display: "inline-block", color: (isTagHovered === false) ? "#0058e8" : "#0445b0", textDecoration: "none"}} onMouseEnter={() => setIsTagHovered(true)} onMouseLeave={() => setIsTagHovered(false)}>   
                                        {tag} 
                                    </a> 
                                <span style={{ margin: "0.4rem" }}></span></span>
                            ))}
                        </div>
                    </Link>
                </a>
            </Card.Body>
        </Card>
    );
}

export default ExperienceCard;