import { useCallback, useEffect, useState } from "react"
import { Card, Button, Image } from "react-bootstrap"

import uwcuHubLogo from "../../../logo/uwcu_logo.png";


export default function Uwcu(props) {



  return (
    <div className="App">
        <div style={{paddingLeft: "25%", paddingRight: "19.5%", paddingTop: "0.5%", alignItems: "center", lineHeight: "150%"}} className="Proj-Description">
            <hr />
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                <h1 style={{display: "inline", margin: 0, paddingTop: "0.5%"}}>UW Credit Union</h1>
            </div>
            <a href="https://www.uwcu.org/" target="_blank" style={{textDecoration: "none"}}>
              <Image src={uwcuHubLogo} alt="UW Credit Union Logo" style={{width: '25%', float: "right", height: '25%', marginTop: "-2.55rem", marginRight: "0.5rem" }}></Image>
            </a>
            <h4 style={{paddingTop: "0.5%", color: "gray"}}>Software Engineer Intern</h4>
            <p style={{ paddingTop: "5%", display: "inline", margin: 0, fontSize: 16, color: "gray"}}>May 2023 - Aug 2023</p>
            <p style={{paddingTop: "2.5%"}}>
              During my internship, I had the opportunity to work on cutting-edge solutions in fraud detection. 
              Collaborating with a team of engineers, I contributed to implementing a Gradient Boosting Machine (GBM)-based machine learning algorithm. 
              This model utilized transaction history and user behavior data to enhance fraudulent transaction detection accuracy by 25%, 
              significantly improving the security of financial operations.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              Beyond algorithm development, I focused on building user-facing tools to streamline fraud detection workflows. 
              I designed and developed a dashboard using React for the frontend and .NET for the backend, 
              enabling seamless interaction with the fraud detection system. This tool reduced processing times by 40%, 
              allowing teams to identify and respond to suspicious activities more efficiently.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              The project also involved collaborating closely with stakeholders to understand their requirements and tailor solutions to their needs. 
              By integrating machine learning predictions into the dashboard, I ensured real-time insights were accessible, 
              enhancing the overall user experience and operational efficiency.
            </p>
            <p style={{paddingTop: "2.5%"}}>
              This hands-on experience not only deepened my understanding of machine learning and full-stack development but also 
              gave me valuable insights into creating impactful solutions for enterprise applications. 
              My contributions to the fraud detection system showcased the potential of blending advanced technology with practical user tools.
            </p>

        </div>
    </div>
  );
}

